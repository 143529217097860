<template>
  <div class="Factory MERCH">
    <div class="merch-card">
      <div class="member-mess-box">
        <div class="member-mess-title">会员信息</div>
        <div class="member-mess">
          <div>
            <div>
              会员编码：<span>{{ UserLoanUserInfoData.id || "/" }}</span>
            </div>
            <div>
              法人姓名：<span>{{ UserLoanUserInfoData.name || "/" }}</span>
            </div>
            <div>
              授信总额：<span>{{ UserLoanUserInfoData.creditLine || "/" }}</span>
            </div>
          </div>
          <div>
            <div>
              单位名称：<span>{{ UserLoanUserInfoData.enterprise || "/" }}</span>
            </div>
            <div>
              证件号码：<span>{{ UserLoanUserInfoData.idCard || "/" }}</span>
            </div>
            <div>
              可用额度：<span>{{ UserLoanUserInfoData.availableCredit || "/" }}</span>
            </div>
          </div>
          <div>
            <div>
              简称：<span>{{ UserLoanUserInfoData.shopName || "/" }}</span>
            </div>
            <div>
              单位内码：<span>{{ UserLoanUserInfoData.erpCode || "/" }}</span>
            </div>
            <div>
              授信时间：<span>{{ UserLoanUserInfoData.startDate || "/" }}</span>
            </div>
          </div>
          <div>
            <div>
              会员类型：<span>{{ UserLoanUserInfoData.type || "/" }}</span>
            </div>
            <div>
              联系人内码：<span>{{ UserLoanUserInfoData.contactsCode || "/" }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="loans-title">贷款信息</div>
      <div class="search">
        <div class="search-box1">
          <el-select
            v-model="searchApi.repayStatus"
            placeholder="结清状态"
            clearable
          >
            <el-option
              v-for="item in stateOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="search-box1">
          <el-input
            v-model="searchApi.number"
            placeholder="借据号/订单号"
          ></el-input>
        </div>
        <div class="search-box1">
          <!-- <el-date-picker
            v-model="searchApi.putoutDay"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker> -->
          <el-date-picker
            v-model="searchApi.putoutDay"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <div class="search-btn" @click="search">查询</div>
      </div>

      <!-- 列表 -->
      <div>
        <div class="table-box">
          <el-table
            
            :data="usersData.list"
            border
            style="width: 100%"
            height="420"
            :header-cell-style="{ background: '#F2F3F5' }"
          >
            <el-table-column
              label="序号"
              align="center"
              prop="index"
              width="60"
            >
            </el-table-column>
            <el-table-column prop="address" label="借据号" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.loanBalanceNo"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.loanBalanceNo }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="放款日期" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.putoutDay"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.putoutDay }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="贷款金额（元）"
              align="center"
            >
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.putoutAmount"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.putoutAmount }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="结清状态" align="center">
              <template slot-scope="{ row }">
                <div v-if="row.repayStatus == 0">未结清</div>
                <div v-if="row.repayStatus == 1">已结清</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="待还金额（元）"
              align="center"
            >
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.availableAmount"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.availableAmount }}</div>
                </el-tooltip>
              </template>
            </el-table-column>

            <el-table-column prop="address" label="订单号" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.orderGroupId"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.orderGroupId }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="应还款日期" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.repayDay"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.repayDay }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="实际还款日期" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.realRepayDay"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.realRepayDay }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="操作"
              align="center"
              width="100"
            >
              <template slot-scope="{ row }">
                <div class="userBtn">
                  <!--  -->
                  <div @click="openAdd(row)">详情</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!-- 分页 -->
      <div class="merch-page">
        <div class="pages">共<span>{{usersData.totalPage}}</span>页/<span>{{usersData.totalRecords}}</span> 条数据</div> 
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :page-size="usersData.per_page"
          layout="prev, pager, next, jumper"
          :total="usersData.totalRecords"
          :current-page="searchApi.page"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const { mapState, mapActions } = createNamespacedHelpers("userLoan"); //vuex公共库
export default {
  data() {
    return {
      stateOption: [
        {
          value: 0,
          label: "未结清",
        },
        {
          value: 1,
          label: "已结清",
        },
      ],
      searchApi: { page: 1, number: "", putoutDay: "", repayStatus: "" },
      usersData: {},
      UserLoanUserInfoData:{},
      // loading: true,
      AddData: {
        sort: 0,
      },

      islogistics: false,
    };
  },

  computed: {
    ...commonIndex.mapState([
      "AreaList",
      "userType",
      "mainHright",
      "allJurisdiction",
      "detailsSeach",
      "userType",
    ]),
  },
  created() {
    let router = this.$router.history.current.path.substr(1);
    if (this.detailsSeach[router]) {
      this.searchApi = this.detailsSeach[router];
    }

    this.toSearch();
    this.UserLoanUserInfo(this.$route.query.userId);
  },
  mounted() {
    this.tableHei = this.mainHright * 1 + 50;
    console.log(this.tableHei);
  },
  methods: {
    ...mapActions(["getUserLoanUserInfo", "postUserLoanList"]),
    ...commonIndex.mapMutations(["changeSeach"]),
    // 获取会员信息
    async UserLoanUserInfo(val) {
      let res = await this.getUserLoanUserInfo(val);
      if (res.code == "000000") {
        // console.log(13223,res)
        this.UserLoanUserInfoData = res.content;
      }
    },
    openAdd(val) {
      this.$router.push({
        path: "/loanDetails",
        query: {
          userId: val.userId,
          number: val.loanBalanceNo,
          id:val.id
        },
      });
    },
    handleCurrentChange(e) {
      this.searchApi.page = e;
      this.toSearch();
    },
    search() {
      this.searchApi.page = 1;
      this.toSearch();
    },
    async toSearch() {
      if(this.searchApi.putoutDay&&this.searchApi.putoutDay.length>0){
        this.searchApi.putoutDayStart=this.searchApi.putoutDay[0]
        this.searchApi.putoutDayEnd=this.searchApi.putoutDay[1]
      }else{
        this.searchApi.putoutDayStart=null
        this.searchApi.putoutDayEnd=null
      }
      let params = this.searchApi;
      params.userId = this.$route.query.userId;
      let data = await this.postUserLoanList(this.searchApi);
      let router = this.$router.history.current.path.substr(1);
      let save = { ...this.searchApi };
      save.router = router;
      this.changeSeach(save);
      console.log(data.data);
      data.content.list.forEach((item, index) => {
        item.index =
          (data.content.pageNumber - 1) * data.content.pageSize + index + 1;
      });
      this.usersData = data.content;
      // this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.dio-input {
  // .el-input {
  //   width: 300px !important;
  // }
  .el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
}
.el-tabs__item.is-active {
  color: #06b7ae;
}
.el-tabs__item:hover {
  color: #06b7ae;
}
.el-tabs__active-bar {
  background-color: #06b7ae;
}
.el-input-group__prepend {
  padding: 0px 0px 0px 5px !important;
}
.el-input__inner {
  border-radius: 4px !important;
}
.el-range-separator {
  width: 20% !important;
}
</style>
<style lang="scss" scoped>
.userBtn {
  display: flex;
  justify-content: center;
  div {
    margin-left: 5px;
    color: #1abca9;
    cursor: pointer;
  }
}
.MERCH {
  width: 100%;
  height: 100%;
  .merch-card {
    width: 100%;
    max-height: 100%;
    padding: 20px;
    padding-top: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    background: #ffffff;
    .merch-tag {
      width: 100%;
      height: 40px;
    }
    // 搜索行样式
    .search {
      display: flex;
      .search-box1 {
        width: 250px;
        margin-right: 20px;
      }
      .search-btn {
        width: 80px;
        height: 40px;
        line-height: 40px;
        background-color: #06b7ae;
        color: #fff;
        text-align: center;
        border-radius: 4px;
        cursor: pointer;
      }
    }
    .merch-table {
      margin-top: 1%;
      width: 100%;
      height: 77%;
    }
    .merch-page {
      margin-top: 10px;
      width: 100%;
      display: flex;
      align-content: center;
      justify-content: right;
      .pages{
        line-height: 32px;
        font-size: 14px;
        color: #31373D;
        span{
          color: #06B7AE;
        }
      }
    }
  }
}
::v-deep .el-select {
  width: 100%;
}
::v-deep .el-date-editor.el-input {
  width: 100%;
}
.table-box {
  margin-top: 20px;
}
.member-mess-title{
  font-size: 16px;
  font-weight: 600;
  padding-top: 13px;
}
.member-mess-title::before {
    width: 4px;
    height: 16px;
    background-color: #06b7ae;
    border-radius: 10px;
    display: inline-block;
    content: "";
    margin-right: 10px;
    margin-bottom: -2px;
  }
.member-mess {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #666;
  padding: 0 10px;
  padding-bottom: 20px;
  > div {
    div {
      margin: 15px 0;
    }
  }
  span {
    color: #222;
  }
}
.loans-title {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
  padding-top: 34px;
  border-top: 1px dashed rgba($color: #000000, $alpha: 0.3);
}
.loans-title::before {
    width: 4px;
    height: 16px;
    background-color: #06b7ae;
    border-radius: 10px;
    display: inline-block;
    content: "";
    margin-right: 10px;
    margin-bottom: -2px;
  }
::v-deep .el-date-editor--daterange.el-input__inner{
  width: 100%;
}
</style>
